import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {AuditUpdateInfoModel, ClientModel, DocumentInfoModel} from '../../models';
import { DateUtils } from '../../utilities';
import { NavigationStart, Router } from '@angular/router';

export interface IPageInfo {
  createdAt?: Date;
  createdBy?: string;
  updatedAt?: Date;
  updatedBy?: string;
  status?: string;
  version?: string;
}
@Injectable({
  providedIn: 'root',
})
export class PageInfoService {
  // Employee portal vars
  public status$ = new BehaviorSubject<string>(null);
  public updatedAt$ = new BehaviorSubject<Date>(null);
  public updatedBy$ = new BehaviorSubject<string>(null);
  public createdAt$ = new BehaviorSubject<Date>(null);
  public createdBy$ = new BehaviorSubject<string>(null);
  public version$ = new BehaviorSubject<string>(null);
  public client$ = new BehaviorSubject<ClientModel>(null);


  // Client portal vars
  public clientNumber$ = new BehaviorSubject<string>(null);
  public clientDateOfBirth$ = new BehaviorSubject<Date>(null);
  public clientName$ = new BehaviorSubject<string>(null);
  public clientAssignedTo$ = new BehaviorSubject<string>(null);
  public careStatus$ = new BehaviorSubject<string>(null);
  public careClosingDate$ = new BehaviorSubject<Date>(null);

  public isEmployeePortal: boolean;
  public isClientSet: boolean;
  public userType: string;

  public statusString$ = this.status$.pipe(
    map((status) => {
      return this.translate.instant(
        `general.status.${status.charAt(0).toUpperCase() + status.slice(1)}`,
      );
    }),
  );

  public careStatusString$ = this.careStatus$.pipe(
    map((status) => {
      return this.translate.instant(
        `general.status.${status.charAt(0).toUpperCase() + status.slice(1)}`,
      );
    }),
  );

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.status$.next('active');
    this.careStatus$.next('open');
    this.updatedAt$.next(DateUtils.getCurrentTimestampDate());
    this.createdBy$.next('');
    this.updatedBy$.next('');
    this.version$.next('');


    this.router.events
    .pipe(filter((event) => event instanceof NavigationStart))
    .subscribe((data: any) => {
      this.setInfo({})
      this.status$.next('');
        this.client$.next(new ClientModel());
      /// this.careStatus$.next('');
    }
    );
  }

  setInfo(pageInfo: IPageInfo): void {
    if (pageInfo.status) this.status$.next(pageInfo.status);
    else this.status$.next('');

    this.createdAt$.next(pageInfo.createdAt);
    this.createdBy$.next(pageInfo.createdBy);
    this.updatedAt$.next(pageInfo.updatedAt);
    this.updatedBy$.next(pageInfo.updatedBy);

    if (pageInfo.version) this.version$.next(pageInfo.version);
    else this.version$.next('');
  }

  setStatus(value: string) {
    this.status$.next(value);
  }

  setUpdateDate(value: Date) {
    this.updatedAt$.next(value);
    this.createdAt$.next(null);
    this.createdBy$.next('');
    this.updatedBy$.next('');
    this.version$.next('');
  }

  setOnlyUpdateDateAndBy(updateInfo: AuditUpdateInfoModel) {
    this.updatedAt$.next(updateInfo.updatedAt);
    this.updatedBy$.next(updateInfo.updatedBy);
  }

  setCareStatus(value: string) {
    this.careStatus$.next(value);
  }

  setClientNumber(value: string) {
    this.clientNumber$.next(value);
  }

  setClient(value: ClientModel) {
    this.client$.next(value);
  }

  setClosingDate(value: Date) {
    this.careClosingDate$.next(value);
  }

  setClientName(value: string) {
    this.clientName$.next(value);
  }

  setClientAssignedTo(value: any[]) {
    const assignedTo = value
      .map((employee) => {
        return employee.name;
      })
      .toString();
    this.clientAssignedTo$.next(assignedTo);
  }

  setClientDateOfBirth(value: Date) {
    this.clientDateOfBirth$.next(value);
  }

  setIsEmployeePortal(value: boolean) {
    this.isEmployeePortal = value;
  }

  setIsClientSet(value: boolean) {
    this.isClientSet = value;
  }

  setUserType(value: string) {
    this.userType = value;
  }

  refresh(path: string, url: string): void {
    this.getDocumentInfo(path, url).subscribe();
  }

  public getDocumentInfo(path: string, url: string) {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: path,
    });
    return this.http
      .get<DocumentInfoModel>(`${url}`, {
        headers: httpHeaders,
      })
      .pipe(
        tap((data) => {
          this.status$.next(data.status);
          this.updatedAt$.next(data.updatedAt);
        }),
      );
  }
}
