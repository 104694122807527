export class TransformFunctions {
  public static upperCase(value: string) {
    return value.toUpperCase();
  }

  public static lowerCase(value: string) {
    return value.toLowerCase();
  }

  public static addressCase(value: string) {
    return value.toUpperCase().replace(/\s/g, '');
  }
}
