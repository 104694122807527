import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ag-grid-wrapper',
  templateUrl: './ag-grid-wrapper.component.html',
  styleUrls: ['./ag-grid-wrapper.component.css'],
})
export class AgGridWrapperComponent implements OnInit {
  @Input() headerText: string;
  @Input() footer: string;
  @Output() addButtonClicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  defaultAddClicked(eventParams: any): void {
    alert('Add functionality has not been implemented yet.');
  }

  public addClicked(eventParams: any): void {
    this.addButtonClicked.emit(eventParams);
  }
}
