import { ComponentRef, TemplateRef } from '@angular/core';
import { SidesheetRef } from './sidesheet-ref';

export const enum Position {
  START = 'start',
  END = 'end',
}

export const enum CommanLineElementPosition {
  RIGHT = 'right',
  LEFT = 'left',
}

export const enum CommandLinePosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export const enum ActionType {
  SIDE_SHEET_CLOSE = 'SideSheetClose',
  SIDE_SHEET_BACK = 'SideSheetBack',
  BACKDROP_CLICK = 'SideSheetBackdropClick',
  ADD = 'Add',
  CLOSE = 'Close',
  CANCEL = 'Cancel',
  BACK = 'Back',
  SAVE = 'Save',
  OK = 'Ok',
  CREATE = 'Create',
  UPDATE = 'Update',
  EDIT = 'Edit',
  DELETE = 'Delete',
  REFRESH = 'Refresh',
  ADD_HOUR = 'add-hour',
  ADD_REPORT = 'add-report',
  SEND_EMAIL = 'send-email'
}

export enum SideSheetSize {
  XS = 'sidesheet-xs',
  SM = 'sidesheet-sm',
  MD = 'sidesheet-md',
  LG = 'sidesheet-lg',
  XL = 'sidesheet-xl',
  XXL = 'sidesheet-xxl',
}

export enum PageMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum SideSheetBackdropType {
  OPAQUE = 'sidesheet-opaque-backdrop',
  TRANSPARENT = 'sidesheet-transparent-backdrop'
}

export interface SideSheetEvent {
  content: SideSheetContent;
  config: SideSheetConfig;
}

export interface SideSheetButtonEvent extends SideSheetEvent {
  button: Button;
}

export interface SideSheetTooltipButtonEvent extends SideSheetButtonEvent {
  confirmed: boolean;
}

export interface SideSheetCheckboxEvent extends SideSheetEvent {
  checkbox: CheckBox;
}

export interface SideSheetDropdownEvent extends SideSheetEvent {
  selectBoxes: SelectBoxes;
  option: SidesheetOption;
}

export interface SideSheetButtonListener {
  onClickButton(event: SideSheetButtonEvent);
}

export interface SideSheetTooltipButtonListener {
  onClickTooltipButton(event: SideSheetTooltipButtonEvent);
}

export interface SideSheetCheckboxListener {
  onCheckboxSelected(event: SideSheetCheckboxEvent);
}

export interface SideSheetDropdownListener {
  onDropdownSelected(event: SideSheetDropdownEvent);
}

export interface SideSheetComponentLoadListener {
  onLoadComponent(event: SideSheetEvent);
  onUnloadComponent(event: SideSheetEvent);
}

export class SidesheetPartStyle {
  drawer?: any;
  titleBar?: any;
  // breadcrumb?: any
  body?: any;
  commandLine?: any;
}

export class ExecutedAction {
  isAction: boolean;
  action: ActionType;
}

export interface ConfirmAction {
  button: Button;
  textHeader: string;
  textContent: string;
  textOk: string;
  textCancel: string;
  headerStyle?: any;
  contentStyle?: any;
}

export interface SideSheetTooltip {
  confirmAction?: ConfirmAction;
  template?: TemplateRef<any>;
}

export interface Button {
  name: string;
  style: any;
  classes: string[];
  actionType: string;
  position?: CommanLineElementPosition;
  disabled?: boolean;
  visible?: boolean;
  loading?: boolean;
}
export interface SidesheetOption {
  key: string;
  value?: string;
  selected: boolean;
}
export interface CheckBox {
  label: string;
  style?: any;
  classes?: string[];
  checked?: boolean;
  disabled?: boolean;
  value?: any;
  position?: CommanLineElementPosition;
}
export interface SelectBoxes {
  label: string;
  style?: any;
  classes?: string[];
  options: SidesheetOption[];
  selectedItem?: SidesheetOption[];
  position?: CommanLineElementPosition;
}

export class SideSheetContent {
  title?: string;
  subTitle?: string;
  buttons?: Button[];
  selectBoxes?: SelectBoxes[];
  checkBoxes?: CheckBox[];
  data?: any;
}

export class SideSheetConfig {
  position?: Position;
  size?: SideSheetSize;
  fullscreen: boolean;
  opacity?: number;
  style?: SidesheetPartStyle;
  closeButton?: boolean;

  hasBackdrop?: boolean;
  backdropType?: SideSheetBackdropType;
  disableCloseOnBackdropClick?: boolean;

  titleBarVisible?: boolean;
  titleBarCloseVisible?: boolean;
  titleBarFullscreenVisible?: boolean;
  titleBarBackButtonVisible?: boolean;

  breadcrumbVisible?: boolean;
  breadcrumbCloseVisible?: boolean;
  breadcrumbFullscreenVisible?: boolean;
  breadcrumbBackButtonVisible?: boolean;

  sidesheetCloseButtonAutoCloseActionEnabled?: boolean;
  sidesheetBackButtonAutoBackActionEnabled?: boolean;

  commandLineVisible?: boolean;
  commandLinePosition: CommandLinePosition;

  tooltipVisible?: boolean;
  closeWithTooltip?: boolean;

  loadingVisible?: boolean;


}

export class FormerSidesheet {
  componentRef: ComponentRef<any>;
  content: SideSheetContent;
  config: SideSheetConfig;
  sidesheetRef: SidesheetRef;
}
