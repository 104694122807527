export class HealthProfileModel {
  id: string;
  height: string;
  heightType: string;
  weight: string;
  weightType: string;
  action: string;
  status: string;
  errorMessage: string;
  errorCode: string;
  createdAt: Date;
  updatedAt: Date;
  displayName: string;
  heartRate: string;
  heartRateType: string;
  bloodPressure: string;
  bloodPressureType: string;
  clientId?: string;
  clientExternalId?: string;
  profileCreatedDate?: Date;
}
