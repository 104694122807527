<div class="radio-group-container_{{ size }}">
  <mat-label id="radio-group-label">{{label | translate}}@if(control?.hasValidator(required)){<span class="required-field">*</span>}</mat-label>
  @if (groupHint) {
    <mat-hint class="custom-input-hint">{{ groupHint | translate }}</mat-hint>
    }
  <mat-radio-group
    aria-labelledby="radio-group-label"
    [class.vertical]="direction==='column'"
    [class.horizontal]="direction==='row'"
    name="{{ controlName }}"
    [formControl]="control"
  >
    @for (option of options; track $index) {
    <mat-radio-button class="{{($index!==0 && !isVertical) ? 'left-spaced' :''}}" [disableRipple]="true" [value]="option.value">
      @if(radioLabelTemplate){
        <ng-container *ngTemplateOutlet="radioLabelTemplate; context: { option: option, index: $index}"> </ng-container>
      }@else{
        {{ option.label | translate }}
      }
    </mat-radio-button>
    }
  </mat-radio-group>
  <mat-error>
    @if(control?.errors && control?.touched){
    <app-validation-message
    [errors]="control.errors"
    ></app-validation-message>
    }
  </mat-error>
</div>
