import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { PaginationDataModel } from '../../models';
import { DropdownServiceBase } from '../../services/dropdown-service.abstract';
import { ModelUtils } from '../../utilities/model.utils';
import { FormControlBase } from '../abstract-form-control';
import {
  DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT,
  DEFAULT_DROPDOWN_SHOW_BLANK,
  IFormDropdownOption,
} from '../form-controls.const';

@Component({
  selector: 'app-form-input-dropdown',
  templateUrl: './form-input-dropdown.component.html',
  styleUrls: ['./form-input-dropdown.component.scss'],
})
export class FormInputDropdownComponent
  extends FormControlBase<FormInputDropdownComponent>
  implements OnInit, OnChanges
{
  @Input() options: IFormDropdownOption[] = [];
  @Input() options$: Observable<IFormDropdownOption[]>;
  @Input() blank = DEFAULT_DROPDOWN_SHOW_BLANK;
  @Input() multiple = false;
  @Input() width: string = '';
  @Input() fullWidth = false;
  @Input() dropdownService: DropdownServiceBase<any>;
  @Input() startOptions: IFormDropdownOption[] = [];
  @Input() requestFrom: string = '';

  // value modification
  @Input() objectAsValue = false;
  @Input() valueProperty: string = 'id';
  @Input() tooltipProperty: string;
  @Input() selectSingle = false;
  @Input() selectAll = false;
  // when value is set to an option that doesn't exist - disables the field
  @Input() clearNonExistantValue = false;

  // searching
  searchControl = new FormControl();
  isLoading = false;
  @Output() search$ = this.searchControl.valueChanges.pipe(
    debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
  );
  @Input() search = false;
  @Input() showToggleAllCheckbox = false;

  // displaying data
  @Input() labelProperty: string;
  @Input() optionDisplayFunction: Function = (option) => {};
  @Input() showValue = false;
  @Input() delimiter = ':';

  // filtering data
  @Input() filterParamName: string;
  @Input() filterParam: any;
  @Input() paginationParam: PaginationDataModel;
  @Input() filterObject: any;
  @Input() frontendFiltering = false;
  @Input() frontendFilteringProperty = 'value';
  @Input() doesNotTakeMissingValue: boolean = false;
  // if set to true the dropdown won't pull any data until a filter is present
  @Input() filterRequired = false;
  @Input() maxContentSelectionPanel = false;

  @Input()
  set listOptions(list: any[]) {
    this.options = list.map((value) => {
      return { value, label: value };
    });
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public translate?: TranslateService,
  ) {
    super(ngControl);
  }

  get tippyOptions() {
    let tippyOptions: string = '';
    if (this.ngControl.value) {
      if (this.objectAsValue) {
        const property = this.tooltipProperty
          ? this.tooltipProperty
          : this.labelProperty;
        for (let value of this.ngControl.value as []) {
          let option = this.options?.find(
            (option) =>
              option[this.valueProperty] === value[this.valueProperty],
          );
          if (option)
            tippyOptions += this.translate.instant(option[property]) + '<br>';
        }
      } else {
        for (let value of this.ngControl.value as []) {
          tippyOptions +=
            this.translate.instant(
              this.options?.find((option) => option.value === value)?.label ||
                ' ',
            ) + '<br>';
        }
      }
    }
    return tippyOptions;
  }

  get hasParams(): boolean {
    if (this.filterObject) {
      for (let value in this.filterObject) {
        if (value == undefined) return false;
      }
    }
    return this.filterObject || this.filterParam;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.dropdownService) {
      this.dropdownService.requestFrom = this.requestFrom;
      if (this.paginationParam)
        this.dropdownService.paginationParams = this.paginationParam;
    }
    this.initSearchSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const paginationParam = changes.paginationParam?.currentValue;
    if (paginationParam) {
      this.dropdownService.paginationParams = paginationParam;
    }
    const filterParam = changes.filterParam?.currentValue;
    if (filterParam) {
      this.dropdownService.filterParams = {
        [this.filterParamName]: filterParam,
      };
    }

    const filterObject = changes.filterObject?.currentValue;
    if (filterObject) {
      this.dropdownService.filterParams = { ...filterObject };
    }
    const values = changes.options?.currentValue;
    if (!changes.options?.isFirstChange()) {
      this.selectSingleValue(values);
    }
    this.selectAllValue(values);

    if (filterObject || filterParam || paginationParam) {
      this.getData(this.searchControl.value);
    }
  }


  private initSearchSubscription() {
    if (this.dropdownService) {
      this.search$
        .pipe(
          startWith(this.searchControl.value),
          distinctUntilChanged(),
          filter((search) => {
            // skip the initial search when a filter is required
            // search is only null when initialized, empty string when user deletes the input
            if (this.filterRequired && search === null) {
              return false;
            }
            // skip if the param is required so we don't make unnecessary requests
            if (this.filterRequired && !this.hasParams) {
              return false;
            }
            return true;
          }, this),
          takeUntil(this.destroy),
        )
        .subscribe((search) => {
          if (this.frontendFiltering) {
            this.options = ModelUtils.searchInArray(
              this.dropdownService.values,
              search,
              'label',
              this.frontendFilteringProperty,
            );
          } else {
            this.getData(search);
          }
        });
    } else {
      this.search$.subscribe((search) => {
        if (this.options$) {
          return (this.options$ = of(
            ModelUtils.searchInArray(
              this.startOptions,
              search,
              this.frontendFilteringProperty,
            ),
          ));
        }
        this.options = ModelUtils.searchInArray(
          this.startOptions,
          search,
          this.frontendFilteringProperty,
        );
      });
    }
  }

  populateDropdown(values: any[]): void {
    let hasValue = false;
    if (!this.multiple) {
      if (this.objectAsValue) {
        hasValue = values.includes((v) => v.id === this.value?.id);
      } else {
        hasValue = values.includes((v) => v === this.value);
      }
    }
    if (hasValue) this.valueControl.patchValue(null);

    // if the current value is missing from the service response
    // it probably got paginated away
    if (this.value) {
      this.multiple
        ? this.handleMultipleMissingValue(values)
        : this.handleSingleMissingValue(values);
    }

    // operations that depend on flags
    this.selectSingleValue(values);
    this.selectAllValue(values);
    //

    this.dropdownService.values = values;
    this.isLoading = false;
  }

  private selectSingleValue(values: any) {
    if (this.selectSingle && values && !this.disabled && values.length === 1) {
      const singleValue = this.objectAsValue
        ? this.multiple
          ? values
          : values[0]
        : values[0].value;
      // we need this check, otherwise it will emit changes (can cause issues on edit)
      if (!this.valueControl.value) this.valueControl.patchValue(singleValue);
      this.blank = false;
    }
  }


  private selectAllValue(values: any) {
    if (this.selectAll && values && !this.disabled && this.multiple) {
      const multipleValue = this.objectAsValue
        ? values
        : values.map((v) => v.value);
      this.valueControl.patchValue(multipleValue);
      this.blank = false;
      this.search = false;
    }
  }

  // hopefuly not needed anymore, but leave it just in case
  private disableWhenNonExistant(values: any[]) {
    if (this.clearNonExistantValue && values && this.value) {
      const existingValue = values.find((value) => {
        if (this.objectAsValue) {
          const optionValue = value ? value[this.valueProperty] : null;
          const controlValue = this.value
            ? this.value[this.valueProperty]
            : null;
          return optionValue === controlValue;
        } else {
          return value === this.value;
        }
      });
      if (!existingValue) {
        this.valueControl.reset({ value: null, disabled: true });
      }
    }
  }

  toggleAll(toggled: boolean) {
    if (toggled) {
      const allValues = this.objectAsValue
        ? [...this.options]
        : this.options.map((option) => option.value);
      this.valueControl.patchValue(allValues);
    } else {
      this.valueControl.patchValue([]);
    }
  }

  public compareObjects = (o1: any, o2: any) => {
    if (this.objectAsValue) {
      const o1Value = o1 ? o1[this.valueProperty] : null;
      const o2Value = o2 ? o2[this.valueProperty] : null;
      return o1Value == o2Value;
    } else {
      return o1 === o2;
    }
  };

  private getData(search?: string): void {
    if (this.filterRequired && !this.hasParams) return;

    this.isLoading = true;

    this.dropdownService
      .filter(search)
      .pipe(
        map((values) => this.dropdownObjectMapper(values)),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((values) => this.populateDropdown(values));
  }

  private dropdownObjectMapper(values) {
    if (this.objectAsValue) {
      return values;
    } else {
      return values.map((value) => {
        const label = this.labelProperty
          ? value[this.labelProperty]
          : this.optionDisplayFunction(value);
        return { value: value[this.valueProperty], label };
      });
    }
  }

  // MISSING VALUES HANDLING
  //
  private handleSingleMissingValue(values: any[]) {
    if (this.checkSingleMissingValue(values)) {
      if (this.objectAsValue) {
        if (this.doesNotTakeMissingValue) return;
        if (!ModelUtils.isObjectEmpty(this.value)) values.unshift(this.value);
      } else {
        if (this.doesNotTakeMissingValue) return;
        this.dropdownService
          .retreiveMissingValue(this.value, this.valueProperty)
          .subscribe((dom) => {
            const missingFormOption = <IFormDropdownOption>{
              value: dom[this.valueProperty],
              label: dom[this.labelProperty],
            };
            values.unshift(missingFormOption);
          });
      }
    }
  }

  private checkSingleMissingValue(values: any[]): boolean {
    if (this.objectAsValue) {
      return values.findIndex(
        (value: any) =>
          value[this.valueProperty] == this.value[this.valueProperty],
      ) === -1
        ? true
        : false;
    } else {
      return values.findIndex((value: any) => value.value === this.value) === -1
        ? true
        : false;
    }
  }

  private handleMultipleMissingValue(values: any[]) {
    if (this.objectAsValue) {
      const missingValues = this.checkMultipleMissingObjects(values);
      values.unshift(...missingValues);
    } else {
      const missingValues: string[] = this.checkMultipleMissingValues(values);

      forkJoin(
        missingValues.map((value) =>
          this.dropdownService.retreiveMissingValue(value),
        ),
      ).subscribe(([...doms]) => {
        const missingFormOptions = doms.map(
          (dom) =>
            <IFormDropdownOption>{
              value: dom[this.valueProperty],
              label: dom[this.labelProperty],
            },
        );
        this.dropdownService.values.unshift(...missingFormOptions);
      });
    }
  }

  private checkMultipleMissingObjects(values: any[]): Object[] {
    const missingValues = [];
    for (let i = 0; i < this.value.length; i++) {
      const existingValue = this.value[i];
      const valueInArray = values.some(
        (value) =>
          value[this.valueProperty] === existingValue[this.valueProperty],
      );
      if (!valueInArray) {
        missingValues.push(existingValue);
      }
    }

    return missingValues;
  }

  private checkMultipleMissingValues(values: any[]): string[] {
    const missingValues = [];

    for (let i = 0; i < this.value.length; i++) {
      const existingValue = this.value[i];
      const valueInArray = values.some(
        (value) => value.value === existingValue,
      );
      if (!valueInArray) {
        missingValues.push(existingValue);
      }
    }

    return missingValues;
  }
}
