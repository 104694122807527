import { IMainMenuItem } from 'c4p-portal-util';

const MAIN_MENU_ITEMS: IMainMenuItem[] = [
  {
    name: 'general.menu.Icommunication',
    icon: 'poll',
    permissions: ['message:getalloutgoing:list', 'message:getallincoming:list'],
    items: [
      {
        name: 'icommunication.menu.Outgoing',
        route: 'icommunication/outgoing',
        permission: 'message:getalloutgoing:list',
      },
      {
        name: 'icommunication.menu.Incoming',
        route: 'icommunication/incoming',
        permission: 'message:getallincoming:list',
      },
    ],
  },
  {
    name: 'general.labels.MedicationSystem',
    icon: 'pool',
    permissions: ['medication-module:manage'],
    items: [
      {
        name: 'general.labels.MedicationSystem',
        route: 'icommunication/medication-system',
        permission: 'medication-module:manage',
      },
      {
        name: 'general.labels.Transaction',
        route: 'icommunication/medication-system/transactions',
        permission: 'medication-module:manage',
      },
    ],
  },
];

export { MAIN_MENU_ITEMS };
