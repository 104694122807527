<mat-form-field class="custom-input-form">
  <mat-label class="custom-input-label">{{ label | translate }}</mat-label>
  @if(prefix){
  <span matPrefix>{{ prefix }}</span>
  }
  <div
  class="custom-input"
  [attr.disabled]="disabled || null"
  > 
  @if(type==='number'){
    <input
      [attr.testId]="testId"
      [formControl]="control"
      [type]="type"
      matInput
      placeholder="{{ placeholder | translate }}"
      [readonly]="disabled"
      [step]="step"
    />
  }@else () {
    <input
      [attr.testId]="testId"
      [formControl]="control"
      [type]="type"
      matInput
      placeholder="{{ placeholder | translate }}"
      [readonly]="disabled"
      [mask]="mask"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [step]="step"
    />
  }
  </div>

  <mat-hint class="custom-input-hint" align="{{alignHint}}">{{ hint ?? '' }}</mat-hint>

  <mat-error>
    @if(control?.errors){
    <app-validation-message [errors]="control.errors"></app-validation-message>
    }
  </mat-error>

  @if(suffix){
  <span matSuffix>{{ suffix }}</span>
  }
</mat-form-field>
