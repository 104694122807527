import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {MEDICATION} from 'c4p-portal-util';

export function bloodPressureValidatorFactory(translate: TranslateService): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    const allowedPattern = /^[a-zA-Z0-9\/]*$/;
    if (!allowedPattern.test(value)) {
      return { bloodPressureFormat: translate.instant('medication.messages.BloodPressureFormat') };
    }

    const parts = value.split('/');
    if (parts.length === 2) {
      const firstNumber = parseInt(parts[0], 10);
      const secondNumber = parseInt(parts[1], 10);
      if (isNaN(firstNumber) || isNaN(secondNumber) ||
        firstNumber < MEDICATION.VALIDATION.BLOOD_PRESSURE_MIN ||
        firstNumber > MEDICATION.VALIDATION.BLOOD_PRESSURE_MAX ||
        secondNumber < MEDICATION.VALIDATION.BLOOD_PRESSURE_MIN ||
        secondNumber > MEDICATION.VALIDATION.BLOOD_PRESSURE_MAX) {
        return { bloodPressureWarning: translate.instant('medication.messages.BloodPressureWarning') };
      }
    } else if (parts.length === 1) {
      const firstNumber = parseInt(parts[0], 10);
      if (isNaN(firstNumber) || firstNumber < MEDICATION.VALIDATION.BLOOD_PRESSURE_MIN ||
        firstNumber > MEDICATION.VALIDATION.BLOOD_PRESSURE_MAX) {
        return { bloodPressureWarning: translate.instant('medication.messages.BloodPressureWarning') };
      }
    }

    return null;
  };
}
