import { TenantConfiguration } from '../../models/tenant-configuration.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantConfigurationService {
  private url = `${environment.tenantManagementApiUrl}`;

  constructor(private httpClient: HttpClient) {}

  public async getKeycloakConfiguration(): Promise<TenantConfiguration> {
    var re = /icom./gi;
    const subdomain = window.location.origin.replace(re, '');

    return await new Promise((resolve, reject) => {
      this.httpClient
        .get(`${this.url}/configuration?subdomain=${subdomain}`)
        .subscribe((response: TenantConfiguration) => {
          resolve(response);
        });
    });
  }
}
