<div class="sidesheet-titlebar-container" [ngStyle]="sideSheetConfig?.style?.titleBar">

    <div class="sidesheet-titlebar-menu-container">
        <button mat-icon-button class="sidesheet-title-button" (click)="onBackButton()" *ngIf="sideSheetConfig?.titleBarBackButtonVisible">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    
        <div class="sidesheet-titlebar-title">{{ sideSheetContent?.title | translate}}</div>
    </div>

    <div class="sidesheet-titlebar-buttons-container">
        @if (titleBarActionsTemplate) {
            <ng-container *ngTemplateOutlet="titleBarActionsTemplate"></ng-container>
        } @else {
            <ng-container *ngTemplateOutlet="sidesheetTitleBarDefaultActionsTemplate"></ng-container>
        }
    </div>
</div>

<ng-template #sidesheetTitleBarDefaultActionsTemplate>
    <button mat-icon-button class="sidesheet-title-button" (click)="toggleFullScreen()"
        *ngIf="sideSheetConfig?.titleBarFullscreenVisible">
        <mat-icon> {{sideSheetConfig?.fullscreen ? "fullscreen_exit": "fullscreen"}}</mat-icon>
    </button>
    <button mat-icon-button class="sidesheet-title-button" (click)="onCloseButton()" [disabled]="closeButton.disabled"
        *ngIf="sideSheetConfig?.titleBarCloseVisible">
        <mat-icon>close</mat-icon>
    </button>
</ng-template>
