<div class="c4p-pageinfo" *ngIf="checkPath() && client?.id" >

  <div class="main">
    <div class="title">{{'medication.titles.MedicationSystemProfile' | translate}}</div>
    <div class="main-row">
      <div class="main-row" *ngIf="medicationProfile && medicationProfile.status === 'pending'; else noPending">
        <div class="progress">
          <div class="progress-bar">
            <div class=""><mat-spinner [diameter]="15"></mat-spinner></div>
            <div class="bar">
              {{'client.messages.MedicationProfileProgress' | translate}}
            </div>
          </div>
          <div class="bar" *ngIf="medicationProfile?.errorMessage">
            {{medicationProfile?.errorMessage}}
          </div>
        </div>
      </div>

      <ng-template #noPending>

        <div class="mp-text-title mp-opacity">
              <span style="font-size: 11px; opacity: 0.5;" *ngIf="medicationProfile?.errorMessage">
                {{medicationProfile?.errorMessage}}
              </span>
        </div>

        <div class="mp-text-title">
          <ng-container *ngIf="medicationProfile && medicationProfile?.medicationProfile">
           <span class="mp-opacity">
             {{'medication.titles.Created' | translate}}
           </span>
            <br>
            <span class="mp-date-title">
              {{medicationProfile?.createdAt | date: 'dd-MM-yyyy HH:mm'}}
            </span>
          </ng-container>
        </div>
        <div class="mp-text-title ml-25">
          <ng-container *ngIf="medicationProfile && medicationProfile?.medicationProfile">
              <span class="mp-opacity">
            {{'medication.titles.Updated' | translate}}
              </span>
            <br>
            <span class="mp-date-title">
              {{medicationProfile?.updatedAt | date: 'dd-MM-yyyy HH:mm'}}
            </span>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="isActive-column">
    <div class="">
      <ng-container
        *ngIf="(medicationProfile && !medicationProfile.medicationProfile && medicationProfile.status !== 'pending') || !medicationProfile">

        <div class="tooltip-wrapper">
          <div class="c4p-ellipsis c4p-mobile-screen c4p-status-icon  active-create-btn "
               (click)="toggleTooltip()">
            {{'general.labels.Create' | translate}}
          </div>

          <div class="tooltip-content" *ngIf="isTooltipVisible">
            <ng-container *ngIf="!createEnabled">

              <p>
                {{'client.messages.MissingFieldsWarning' | translate}}
              </p>
              <div class="tooltip-btn">
                <button type="button" class="c4p-button-cancel t-btn " style="width: 100%;" (click)="closeTooltip()">
                  {{'general.labels.Close' | translate}}
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="createEnabled">
              <p><span >{{'client.messages.CreateMedicationProfile' | translate}}</span></p>
              <div class="tooltip-btn">
                <div class="">
                  <button type="button" class="c4p-button-cancel t-btn" (click)="closeTooltip()">
                    {{'general.labels.Cancel' | translate}}
                  </button>
                </div>
                <div class="">
                  <button type="button" class="c4p-button t-btn" (click)="createMedicationProfile()">
                    {{'general.labels.Confirm' | translate}}
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="!medicationProfile || (medicationProfile && !medicationProfile.medicationProfile)">
        <br>
        <div class="c4p-ellipsis c4p-mobile-screen c4p-status-icon c4p-status-icon-inactive">
          {{'medication.titles.Inactive'| translate}}
        </div>
      </ng-container>
    </div>
    <div class="mt-20">
      <ng-container *ngIf="medicationProfile && medicationProfile?.medicationProfile">
          <span class="c4p-ellipsis c4p-mobile-screen c4p-status-icon c4p-status-icon-active active-padding">
                {{'medication.titles.Active'| translate}}
         </span>
      </ng-container>
    </div>
  </div>
</div>
