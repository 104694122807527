<div class="page-info-bar">
  <ng-container *ngIf="pageInfoService.isEmployeePortal" [formGroup]="form">

      <app-medication-profile></app-medication-profile>

    <div class="c4p-pageinfo" [ngClass]="{'left-border': checkPath()}" >
      <div class="main">
        <div class="c4p-tablet-screen title" *ngIf="
        (pageInfoService.status$ | async) != '' && pageInfoService.isEmployeePortal && checkPath()">
          {{'client.titles.ClientStatus' | translate}}
        </div>
        <div class="main-row">
          <div class=" c4p-tablet-screen mp-text-title" *ngIf="form.controls['createdBy']?.value">
            <span class="mp-opacity"> {{'general.labels.CreatedBy' | translate}} </span>
            <br>
            <span class="mp-date-title">
              {{form.controls['createdBy']?.value}}
            </span>
          </div>

          <div class="c4p-tablet-screen mp-text-title ml-25" *ngIf="form.controls['createdAt']?.value">
            <span class="mp-opacity"> {{'general.labels.CreatedAt' | translate}} </span>
            <br>
            <span class="mp-date-title">
              {{form.controls['createdAt']?.value}}
            </span>
          </div>

          <div class="c4p-mobile-screen mp-text-title ml-25" *ngIf="form.controls['updatedBy']?.value">
            <span class="mp-opacity"> {{'general.labels.UpdatedBy' | translate}} </span>
            <br>
            <span class="mp-date-title">
              {{form.controls['updatedBy']?.value}}
            </span>
          </div>

          <div class="c4p-mobile-screen mp-text-title" *ngIf="form.controls['updatedAt']?.value"
               [ngClass]="{'ml-25': !checkPath()}">
            <span class="mp-opacity"> {{'general.labels.UpdatedAt' | translate}} </span>
            <br>
            <span class="mp-date-title">
              {{form.controls['updatedAt']?.value}}
            </span>
          </div>

        </div>
      </div>

    </div>

  </ng-container>

  <span
    *ngIf="
        (pageInfoService.version$ | async) != '' &&
        pageInfoService.isEmployeePortal
      "
    class="c4p-status-icon c4p-mobile-screen"
  >v{{ pageInfoService.version$ | async }}</span
  >

  <span
    *ngIf="
        (pageInfoService.status$ | async) != '' &&
        pageInfoService.isEmployeePortal
      "
    class="c4p-status-icon c4p-status-icon-{{
        pageInfoService.status$ | async
      }} c4p-ellipsis c4p-mobile-screen"
  >{{ pageInfoService.statusString$ | async }}</span
  >

  <div
    class="c4p-mobile-screen"
    style="padding-bottom: 10px"
    *ngIf="
        (pageInfoService.clientName$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
  >
      <span class="c4p-form-label" translate
      >clientPortal.labels.CareFileOf</span
      >

    <span class="c4p-form-label">
        {{ pageInfoService.clientName$ | async }}</span
    >
  </div>

  <div
    class="c4p-horizontal-divider c4p-mobile-screen"
    *ngIf="
        (pageInfoService.careStatus$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
  >
    <span class="c4p-form-label" translate>clientPortal.labels.Care</span>
    <span class="c4p-form-label">: </span>
    <span
      class="c4p-status-icon c4p-status-icon-{{
          pageInfoService.careStatus$ | async
        }} c4p-ellipsis"
    >
        {{ pageInfoService.careStatusString$ | async }}</span
    >
  </div>

  <div
    class="c4p-horizontal-divider c4p-mobile-screen"
    *ngIf="
        (pageInfoService.clientNumber$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
  >
      <span class="c4p-form-label" translate
      >clientPortal.labels.ClientNumber</span
      >
    <span class="c4p-form-label">: </span>
    <span class="c4p-form-label">{{
      pageInfoService.clientNumber$ | async
      }}</span>
  </div>

  <div
    class="c4p-horizontal-divider c4p-mobile-screen"
    *ngIf="
        (pageInfoService.clientDateOfBirth$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
  >
      <span class="c4p-form-label" translate
      >clientPortal.labels.DateOfBirth</span
      >
    <span class="c4p-form-label">: </span>
    <span class="c4p-form-label">{{
      pageInfoService.clientDateOfBirth$ | async | appDate
      }}</span>
  </div>

  <div
    class="c4p-horizontal-divider c4p-mobile-screen"
    *ngIf="
        (pageInfoService.clientAssignedTo$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
  >
      <span class="c4p-form-label" translate
      >clientPortal.labels.AssignedTo</span
      >
    <span class="c4p-form-label">: </span>
    <span class="c4p-form-label">{{
      pageInfoService.clientAssignedTo$ | async
      }}</span>
  </div>
</div>
