import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionService } from '../permissions/permission.service';
import { map } from 'rxjs/operators';
import { IMainMenuItem, IMainSubMenuItem, SidebarConfiguration } from '../../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  // set from SPA
  public sidebarItems$ = new BehaviorSubject<IMainMenuItem[]>([]);

  // maps calculated permissions for every menu item
  public get mainMenuItems$(): Observable<IMainMenuItem[]> {
    return this.sidebarItems$.pipe(
      map((sidebarItems) => {
        return sidebarItems.map((sidebarItem) => {
          let hasPermission = false;
          if (sidebarItem.permissions) {
            sidebarItem.permissions.forEach((permission) => {
              let access = this.permissionService.getPermission(permission);
              if (access) hasPermission = access;
            });
          }
          sidebarItem.hasPermission = hasPermission;

          sidebarItem.items.forEach((item) => {
            let hasPermission = true;
            if (item.permission)
              hasPermission = this.permissionService.getPermission(
                item.permission,
                item.permissionOperator,
              );
            item.hasPermission = hasPermission;
          });
          return sidebarItem;
        });
      }),
    );
  }

  private postProcessSidebarItems(
    sidebarConfigurations: SidebarConfiguration[],
    sidebarItems: IMainMenuItem[],
  ) {
    sidebarItems?.forEach((rootItem) => {
      rootItem?.items.forEach((subItem) =>
        this.postProcessSidebarItem(subItem, rootItem, sidebarConfigurations),
      );
    });
  }

  postProcessSidebarItem(
    subItem: IMainSubMenuItem,
    rootItem: IMainMenuItem,
    sidebarConfigurations: SidebarConfiguration[],
  ): void {
    const found = sidebarConfigurations.find(
      (configuration) =>
        subItem.name == configuration.itemLabel &&
        rootItem.name == configuration.collectionLabel,
    );
    if (found) {
      if (found.permission) {
        subItem.permission = found.permission;
      }
      if (found.route) {
        subItem.route = found.route;
      }
    }
  }

  private sortSidebarItems(
    sidebarConfigurations: SidebarConfiguration[],
    sidebarItems: IMainMenuItem[],
  ) {
    for (let sidebarItem of sidebarItems) {
      let maxOrder = 0;
      for (let subItem of sidebarItem.items) {
        for (let configuration of sidebarConfigurations) {
          if (
            subItem.name == configuration.itemLabel &&
            sidebarItem.name == configuration.collectionLabel
          ) {
            subItem.order = configuration.order;
            maxOrder = Math.max(maxOrder, subItem.order);
            break;
          }
        }
      }
      for (let subItem of sidebarItem.items) {
        if (!subItem.order) {
          maxOrder++;
          subItem.order = maxOrder;
        }
      }
      sidebarItem.items.sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        } else if (a.order > b.order) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      });
    }
    this.sidebarItems$.next(sidebarItems);
  }

  constructor(
    private permissionService: PermissionService,
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {}

  setSidebarItems(sidebarItems: IMainMenuItem[]) {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/tenants/sidebar-configuration',
    });
    this.http
      .get<SidebarConfiguration[]>(
        `${this.environment.tenantManagementApiUrl}/sidebar-configuration`,
        { headers: httpHeaders },
      )
      .subscribe(
        (result) => {
          this.postProcessSidebarItems(result, sidebarItems);
          this.sortSidebarItems(result, sidebarItems);
        },
        (error) => {
          this.sidebarItems$.next(sidebarItems);
        },
      );
  }

  getSidebarItems() {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/tenants/sidebar-configuration',
    });
    return this.http.get<SidebarConfiguration[]>(
      `${this.environment.tenantManagementApiUrl}/sidebar-configuration`,
      { headers: httpHeaders },
    );
  }
}
