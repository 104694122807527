<div class="sidesheet-breadcrumb-container">
    <div class="sidesheet-breadcrumb-menu-container">
        <div class="sidesheet-breadcrumb-back-button-wrapper" *ngIf="sideSheetConfig?.breadcrumbBackButtonVisible">
            <button mat-icon-button class="sidesheet-breadcrumb-button" (click)="onBackButton()">
              <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>

        <ol class="sidesheet-breadcrumb">

          <li *ngIf="sideSheetContent?.subTitle" class="sidesheet-breadcrumb-item-subtitle">
            <span>{{sideSheetContent?.subTitle | translate}}
            </span> |
          </li>
            <li *ngFor="let item of formerSidesheets; let isLast = last; let i = index"
                [class.active]="isLast" class="sidesheet-breadcrumb-item" >

                <ng-container *ngIf="isLast">
                  <span>{{item?.content?.title | translate}}</span>
                </ng-container>

                <ng-container *ngIf="!isLast">
                    <a *ngIf="!isLast" href="javascript:void(0)"
                        (click)="loadComponent(item?.componentRef?.componentType, item?.content, item?.config)">{{item?.content?.title | translate}}</a>
                </ng-container>
            </li>
        </ol>
    </div>

    <div class="sidesheet-breadcrumb-buttons-container">
        @if (breadcrumbActionsTemplate) {
            <ng-container *ngTemplateOutlet="breadcrumbActionsTemplate"></ng-container>
        } @else {
            <ng-container *ngTemplateOutlet="sidesheetBreadCrumbDefaultActionsTemplate"></ng-container>
        }
    </div>

</div>

<ng-template #sidesheetBreadCrumbDefaultActionsTemplate>
    <button mat-icon-button class="sidesheet-breadcrumb-button" (click)="toggleFullScreen()"
        *ngIf="sideSheetConfig?.breadcrumbFullscreenVisible">
        <mat-icon> {{sideSheetConfig?.fullscreen ? "fullscreen_exit": "fullscreen"}}</mat-icon>
    </button>
    <button mat-icon-button class="sidesheet-breadcrumb-button" (click)="onCloseButton()"
        [disabled]="closeButton.disabled" *ngIf="sideSheetConfig?.breadcrumbCloseVisible">
        <mat-icon>close</mat-icon>
    </button>
</ng-template>
