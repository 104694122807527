
<div class="text-area-wrapper">
  <div [attr.disabled]="control.disabled || null" class="border-shadow"></div>
  <mat-form-field class="textarea-input-field" appearance="fill" [attr.filled]="valueIsNotEmpty || null" >
    @if(label){
      <mat-label>{{label | translate}}</mat-label>
    }
    <textarea
      [attr.testId]="testId"
      type="{{ type }}"
      matInput
      [formControl]="control"
      placeholder="{{placeholder|translate}}"
      [ngStyle]="{ resize: disableResize ? 'none' : '' }"
      cdkTextareaAutosize="autoResize"
      [readonly]="disabled"
    ></textarea>


    <mat-error>
      @if(control?.errors){
      <app-validation-message
      [errors]="control.errors"
      ></app-validation-message>
      }
    </mat-error>
  </mat-form-field>
</div>