<div class="sidesheet-commandline-container" [ngStyle]="sideSheetConfig?.style?.commandLine">
    @if (commandLineTemplate) {
        <ng-container *ngTemplateOutlet="commandLineTemplate"></ng-container>
    } @else {
        <ng-container *ngTemplateOutlet="sideSheetCommandLineDefaultTemplate"></ng-container>
    }
</div>


<ng-template #sideSheetCommandLineDefaultTemplate>
    <ng-container *ngFor="let buttonItem of sideSheetContent?.buttons; let indx = index">
        <ng-container *ngIf="buttonItem.visible">
            <ng-container
                *ngTemplateOutlet="sideSheetCommandLineButton;context:{buttonItem: buttonItem}"></ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngFor="let selectboxItem of sideSheetContent?.selectBoxes; let indx = index">
        <ng-container>
            <ng-container
                *ngTemplateOutlet="sideSheetCommandLineSelectBox;context:{selectboxItem: selectboxItem}"></ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngFor="let item of sideSheetContent?.checkBoxes; let indx = index">
        <ng-container>
            <ng-container
                *ngTemplateOutlet="sideSheetCommandLineCheckBox;context:{checkboxItem: item}"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #sideSheetCommandLineButton let-item='buttonItem'>
  <div class="sidesheet-commandline-button-wrapper">
    <button class="sidesheet-commandline-button" [ngClass]="item?.classes?.join(' ')" [ngStyle]="item?.style"
            [disabled]="item.disabled || item?.loading" (click)="onCommanlineButtonClicked(item)">
      {{ item.name | translate }}
    </button>
    <ng-container *ngIf="item?.loading">
      <mat-icon>
        <mat-spinner color="primary" diameter="20" class="custom-spinner"></mat-spinner>
      </mat-icon>
    </ng-container>
  </div>
</ng-template>

<ng-template #sideSheetCommandLineSelectBox let-item='selectboxItem'>
    <mat-label>{{ item.label | translate}}</mat-label>
    <mat-select [(value)]="selectedItems[i]" [ngClass]="item?.classes?.join(' ')" [ngStyle]="item?.style">
        <ng-container *ngFor="let option of item.options">
            <mat-option [value]="option.key" (click)="onExecuteDropdownAction(item, option)">
                <span>{{ option.value }}</span>
            </mat-option>
        </ng-container>
    </mat-select>
</ng-template>

<ng-template #sideSheetCommandLineCheckBox let-item='checkboxItem'>
    <div class="c4p-form-input-small">
        <mat-checkbox [ngModel]="item.checked" (change)="onExecuteCheckboxAction($event.checked, item)"
            [disabled]="item.disabled" [labelPosition]="'after'" [ngClass]="item?.classes?.join(' ')"
            [ngStyle]="item?.style">
            {{ item.label | translate }}
        </mat-checkbox>
    </div>
</ng-template>
