import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {
  ActionType, ClientInfoService,
  ClientModel,
  GeneralUtil,
  HealthProfileFields,
  MedicationProfileFields,
  MedicationProfileModel,
  PageInfoService, PATH
} from 'c4p-portal-util';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-medication-profile',
  templateUrl: './medication-profile.component.html',
  styleUrls: ['./medication-profile.component.scss'],
})
export class MedicationProfileComponent implements OnInit {
  medicationProfile: MedicationProfileModel;
  client: ClientModel;
  emptyFields: string[];
  private readonly destroyed$ = new Subject<boolean>();
  createEnabled: boolean = false;
  isTooltipVisible = false;
  constructor(
    public pageInfoService: PageInfoService,
    private location: Location,
    private clientInfoService: ClientInfoService,
    public toaster: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.pageInfoService.client$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((client) => {
        this.client = client;
        this.medicationProfile = client?.medicationProfile;
        this.checkMedicationProfileFields()
      });
  }

  checkMedicationProfileFields() {
    if(this.client?.id){
      if(this.client?.healthProfile){
        const fields = GeneralUtil.extractFields(this.client, MedicationProfileFields);
        if(!HealthProfileFields.every(field => field in fields)) return false;
        this.emptyFields = GeneralUtil.findEmptyFields(fields)
        this.createEnabled = GeneralUtil.isObjectValid(fields);
      }else{
        this.emptyFields = HealthProfileFields;
        this.createEnabled = false
      }
      return this.createEnabled;
    }else {
      return false;
    }
  }
  checkPath(){
    const currentRoute = this.location.path();
    return (currentRoute.split('/'))[1] !== PATH.APPLICATION
  }

  createMedicationProfile() {
    if(this.createEnabled){
      this.client.medicationProfile = {
        action: ActionType.CREATE,
        medicationProfile: false,
        createdAt: new Date(),
      };
      this.closeTooltip();
      this.clientInfoService.createMedicationProfile(this.client).subscribe(data=>{
        if(data?.medicationProfile){
          if(data?.medicationProfile?.errorMessage){
            this.toaster.error(this.translate.instant(data?.medicationProfile?.errorMessage));
          }else {
            this.toaster.success(this.translate.instant("medication.messages.CreatingSuccessfully"));
            this.pageInfoService.setClient(data)
            setTimeout(()=>{
              this.clientInfoService.getClientDetail(data.id).subscribe(resp=>{
                this.pageInfoService.setClient(data)
              })
            },15000)
          }
        }
      })
    }else{
      this.toaster.warning(this.translate.instant("medication.messages.CheckHealthInfo"));
    }
  }

  toggleTooltip() {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  closeTooltip() {
    this.isTooltipVisible = false;
  }
}
