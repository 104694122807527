import {
  ActionType,
  CommandLinePosition,
  Position,
  SideSheetContent,
  SideSheetConfig,
  Button,
  CheckBox,
  SelectBoxes,
  ConfirmAction,
  SideSheetSize,
  CommanLineElementPosition,
  SideSheetBackdropType,
} from '../../models/side-sheet';
export function createDefaultSideSheetConfig(): SideSheetConfig {
  return {
    position: Position.END,
    opacity: 1,
    style: {
      drawer: {},
      titleBar: {},
      // breadcrumb: {},
      body: {},
      commandLine: {},
    },
    size: SideSheetSize.SM,
    fullscreen: false,

    hasBackdrop: true,
    backdropType: SideSheetBackdropType.OPAQUE,
    disableCloseOnBackdropClick: true,

    titleBarVisible: false,
    titleBarCloseVisible: true,
    titleBarFullscreenVisible: true,
    titleBarBackButtonVisible: true,

    breadcrumbVisible: true,
    breadcrumbCloseVisible: true,
    breadcrumbFullscreenVisible: true,
    breadcrumbBackButtonVisible: true,

    sidesheetCloseButtonAutoCloseActionEnabled: true,
    sidesheetBackButtonAutoBackActionEnabled: true,

    commandLineVisible: true,
    commandLinePosition: CommandLinePosition.BOTTOM,

    tooltipVisible: true,
    closeWithTooltip: false,
  };
}

export function createDefaultSideSheetContent(): SideSheetContent {
  return {
    title: '',
    subTitle: '',
    buttons: [
      createSideSheetCloseButton(),
      createSideSheetBackButton(),
      createSideSheetDeleteButton(),
      createSideSheetCreateButton(),
    ],

    selectBoxes: [
      // createSideSheetCommondlineExampleSelectBoxes('Select Env 1'),
      // createSideSheetCommondlineExampleSelectBoxes('Select Env 2'),
    ],
    checkBoxes: [
      // createSideSheetCommondlineExampleCheckbox('CheckBox 1', 1),
      // createSideSheetCommondlineExampleCheckbox('CheckBox 2', 2),
    ],
  };
}

export function createConfirmAction(
  button: Button,
  title: string = 'general.labels.Back',
  content: string = 'general.messages.LeavePageMessage',
): ConfirmAction {
  return {
    button,
    textHeader: title,
    textContent: content,
    textOk: 'general.labels.Yes',
    textCancel: 'general.labels.No',
    headerStyle: {},
    contentStyle: {},
  };
}

export function createButton(
  name: string,
  actionType: string,
  style?: any,
  classes?: string[],
  position: CommanLineElementPosition = CommanLineElementPosition.RIGHT,
): Button {
  return {
    name,
    actionType,
    style,
    classes,
    position,
    disabled: false,
    visible: true,
    loading: false,
  };
}

export function createSideSheetCloseButton(): Button {
  return createButton(
    'general.labels.Close',
    ActionType.CLOSE,
    {},
    ['c4p-button-cancel'],
    CommanLineElementPosition.LEFT,
  );
}

export function createSideSheetBackButton(): Button {
  return createButton(
    'general.labels.Back',
    ActionType.BACK,
    {},
    ['c4p-button-cancel'],
    CommanLineElementPosition.LEFT,
  );
}

export function createSideSheetCancelButton(): Button {
  return createButton('general.labels.Cancel', ActionType.CANCEL, {}, [
    'c4p-button-cancel',
  ]);
}

export function createSideSheetEditButton(): Button {
  return createButton('general.labels.Edit', ActionType.EDIT, {}, [
    'c4p-button-ok',
  ]);
}

export function createSideSheetCreateButton(): Button {
  return createButton('general.labels.Create', ActionType.CREATE, {}, [
    'c4p-button-ok',
  ]);
}

export function createSideSheetSaveButton(): Button {
  return createButton('general.labels.Save', ActionType.SAVE, {}, [
    'c4p-button-ok',
  ]);
}
export function createSideSheetOkButton(): Button {
  return createButton('OK', ActionType.OK, {}, ['c4p-button-ok']);
}

export function createSideSheetDeleteButton(): Button {
  return createButton('general.labels.Delete', ActionType.DELETE, {}, [
    'c4p-button-ok',
  ]);
}

export function createSideSheetCommondlineExampleCheckbox(
  label: string,
  value,
): CheckBox {
  return {
    label,
    style: {},
    classes: ['c4p-ellipsis'],
    checked: false,
    disabled: false,
    value,
  };
}

export function createSideSheetCommondlineExampleSelectBoxes(
  label: string,
): SelectBoxes {
  return {
    label,
    options: [
      {
        key: 'option1',
        value: 'Option 1',
        selected: false,
      },
      {
        key: 'option2',
        value: 'Option 2',
        selected: false,
      },
    ],
    style: {},
    classes: ['mat-mdc-select-panel'],
  };
}
