import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DEFAULT_ERRORS_MAP, PATTERN } from '../../form-controls';
import { MatFormFieldModule } from '@angular/material/form-field';

type ErrorValue = {
  [key: string]: any;
};

@Component({
  selector: 'app-validation-message',
  templateUrl: 'validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
  standalone: true,
  imports: [TranslateModule, MatFormFieldModule],
})
export class ValidationMessageComponent {
  @Input() errors;

  errorMessage() {
    for (const errorKey in this.errors) {
      if (this.errors.hasOwnProperty(errorKey)) {
        return this.getValidatorErrorMessage(
          errorKey,
          this.errors[errorKey],
        );
      }
    }
    return null;
  }

  getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    if (validatorName === 'pattern') {
      return [this.getPatternErrorMessage(validatorValue?.requiredPattern)];
    }

    return [
      DEFAULT_ERRORS_MAP.get(validatorName),
      this.getValueError(validatorName, validatorValue),
    ];
  }

  private getValueError(key: string, value: ErrorValue): string | ErrorValue {
    const errorValues: Record<
      string,
      (value: ErrorValue) => string | ErrorValue
    > = {
      min: (value: ErrorValue) => value?.min,
      max: (value: ErrorValue) => value?.max,
      minLength: (value: ErrorValue) => value?.requiredLength,
      maxLength: (value: ErrorValue) => value?.requiredLength,
      modulo: (value: ErrorValue) => JSON.stringify(value),
    };

    return errorValues[key]?.(value) || '';
  }

  private getPatternErrorMessage(pattern: string): string {
    switch (pattern) {
      case PATTERN.numbersOnly:
        return 'general.form.InvalidInteger';
      case PATTERN.distance:
        return 'general.form.InvalidDistance';
      case PATTERN.vat:
        return 'general.form.InvalidVatNumber';
      case PATTERN.iban:
        return 'general.form.InvalidIbanNumber';
      case PATTERN.phoneNumber:
        return 'general.form.InvalidPhoneNumber';
      case PATTERN.password:
        return 'general.labels.InvalidPassword';
      case PATTERN.phoneComp:
        return 'general.form.InvalidPhone';

      default:
        return 'general.form.InvalidPattern'; // Fallback for unknown patterns
    }
  }
}
